import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from 'react-query'
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables>(endpoint: string, requestInit: RequestInit, query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const res = await fetch(endpoint, {
      method: 'POST',
      ...requestInit,
      body: JSON.stringify({ query, variables }),
    })

    const json = await res.json()

    if (json.errors) {
      const { message } = json.errors[0]

      throw new Error(message)
    }

    return json.data
  }
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  AWSDate: string,
  AWSDateTime: string,
  AWSEmail: string,
  AWSIPAddress: string,
  AWSJSON: string,
  AWSPhone: string,
  AWSTime: string,
  AWSTimestamp: number,
  AWSURL: string,
};

export type AddProjectSlotInput = {
  projectId: Scalars['ID'],
  requiredProjectRole: ProjectMemberRole,
  requiredTeamType: TeamType,
};

export type ArchitectProfile = {
  __typename?: 'ArchitectProfile',
  companyAddress?: Maybe<WeaverAddress>,
  companyNumber: Scalars['String'],
  companyRegisteredName: Scalars['String'],
  companyTradingAs?: Maybe<Scalars['String']>,
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export type AssignProjectToWeaverUserInput = {
  projectId: Scalars['ID'],
  weaverTeamId: Scalars['ID'],
};

export enum BudgetRange {
  F30T100 = 'F30T100',
  F100T250 = 'F100T250',
  F250T500 = 'F250T500',
  F500T1000 = 'F500T1000',
  F1000T3000 = 'F1000T3000'
}

export type ChatRoom = {
  __typename?: 'ChatRoom',
  createdAt: Scalars['AWSDateTime'],
  icon?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  individualConnections: Array<IndividualChatRoomConnection>,
  lastMessageAt: Scalars['AWSDateTime'],
  messages?: Maybe<Array<ChatRoomMessage>>,
  name?: Maybe<Scalars['String']>,
  teamConnections: Array<TeamChatRoomConnection>,
  updatedAt: Scalars['AWSDateTime'],
};

export type ChatRoomMessage = {
  __typename?: 'ChatRoomMessage',
  chatRoom: ChatRoom,
  chatRoomId: Scalars['ID'],
  chatRoomMessageDocumentCount?: Maybe<Scalars['Int']>,
  chatRoomMessageDocuments?: Maybe<Array<ChatRoomMessageDocument>>,
  content: Scalars['String'],
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  idFromClient: Scalars['ID'],
  readAt?: Maybe<Scalars['AWSDateTime']>,
  receivedByIndividual: Individual,
  receivedByIndividualId: Scalars['ID'],
  sentAt: Scalars['AWSDateTime'],
  sentByIndividual: Individual,
  sentByIndividualId: Scalars['String'],
  updatedAt: Scalars['AWSDateTime'],
};

export type ChatRoomMessageDocument = {
  __typename?: 'ChatRoomMessageDocument',
  archived?: Maybe<Scalars['Boolean']>,
  chatRoom: ChatRoom,
  chatRoomId: Scalars['ID'],
  chatRoomMessage: ChatRoomMessage,
  chatRoomMessageIdFromClient: Scalars['ID'],
  createdAt: Scalars['AWSDateTime'],
  fileContentType: Scalars['String'],
  fileName: Scalars['String'],
  fileSizeInBytes?: Maybe<Scalars['Int']>,
  id: Scalars['ID'],
  s3Bucket: Scalars['String'],
  s3Key: Scalars['String'],
  signedUrlForDownload: Scalars['String'],
  signedUrlForUpload: Scalars['String'],
  updatedAt: Scalars['AWSDateTime'],
  uploadedByIndividual: Individual,
  uploadedByIndividualId: Scalars['String'],
};


export type ChatRoomMessageDocumentSignedUrlForDownloadArgs = {
  disposition?: InputMaybe<ContentDisposition>,
};

export type CompaniesHouseResponse = {
  __typename?: 'CompaniesHouseResponse',
  apiHealthy: Scalars['Boolean'],
  results: Array<CompanySummary>,
};

export type CompanySummary = {
  __typename?: 'CompanySummary',
  accounts_AccountCategory?: Maybe<Scalars['String']>,
  accounts_AccountRefDay?: Maybe<Scalars['String']>,
  accounts_AccountRefMonth?: Maybe<Scalars['String']>,
  accounts_LastMadeUpDate?: Maybe<Scalars['String']>,
  accounts_NextDueDate?: Maybe<Scalars['String']>,
  companyCategory?: Maybe<Scalars['String']>,
  companyName: Scalars['String'],
  companyNumber: Scalars['String'],
  companyStatus?: Maybe<Scalars['String']>,
  confStmtLastMadeUpDate?: Maybe<Scalars['String']>,
  confStmtNextDueDate?: Maybe<Scalars['String']>,
  countryOfOrigin?: Maybe<Scalars['String']>,
  dissolutionDate?: Maybe<Scalars['String']>,
  incorporationDate?: Maybe<Scalars['String']>,
  limitedPartnerships_NumGenPartners?: Maybe<Scalars['String']>,
  limitedPartnerships_NumLimPartners?: Maybe<Scalars['String']>,
  mortgages_NumMortCharges?: Maybe<Scalars['String']>,
  mortgages_NumMortOutstanding?: Maybe<Scalars['String']>,
  mortgages_NumMortPartSatisfied?: Maybe<Scalars['String']>,
  mortgages_NumMortSatisfied?: Maybe<Scalars['String']>,
  previousName_1_CONDATE?: Maybe<Scalars['String']>,
  previousName_1_CompanyName?: Maybe<Scalars['String']>,
  previousName_2_CONDATE?: Maybe<Scalars['String']>,
  previousName_2_CompanyName?: Maybe<Scalars['String']>,
  previousName_3_CONDATE?: Maybe<Scalars['String']>,
  previousName_3_CompanyName?: Maybe<Scalars['String']>,
  previousName_4_CONDATE?: Maybe<Scalars['String']>,
  previousName_4_CompanyName?: Maybe<Scalars['String']>,
  previousName_5_CONDATE?: Maybe<Scalars['String']>,
  previousName_5_CompanyName?: Maybe<Scalars['String']>,
  previousName_6_CONDATE?: Maybe<Scalars['String']>,
  previousName_6_CompanyName?: Maybe<Scalars['String']>,
  previousName_7_CONDATE?: Maybe<Scalars['String']>,
  previousName_7_CompanyName?: Maybe<Scalars['String']>,
  previousName_8_CONDATE?: Maybe<Scalars['String']>,
  previousName_8_CompanyName?: Maybe<Scalars['String']>,
  previousName_9_CONDATE?: Maybe<Scalars['String']>,
  previousName_9_CompanyName?: Maybe<Scalars['String']>,
  previousName_50_CONDATE?: Maybe<Scalars['String']>,
  previousName_50_CompanyName?: Maybe<Scalars['String']>,
  regAddress_AddressLine1?: Maybe<Scalars['String']>,
  regAddress_AddressLine2?: Maybe<Scalars['String']>,
  regAddress_CareOf?: Maybe<Scalars['String']>,
  regAddress_Country?: Maybe<Scalars['String']>,
  regAddress_County?: Maybe<Scalars['String']>,
  regAddress_POBox?: Maybe<Scalars['String']>,
  regAddress_PostCode?: Maybe<Scalars['String']>,
  regAddress_PostTown?: Maybe<Scalars['String']>,
  returns_LastMadeUpDate?: Maybe<Scalars['String']>,
  returns_NextDueDate?: Maybe<Scalars['String']>,
  sicCode_SicText_1?: Maybe<Scalars['String']>,
  sicCode_SicText_2?: Maybe<Scalars['String']>,
  sicCode_SicText_3?: Maybe<Scalars['String']>,
  sicCode_SicText_4?: Maybe<Scalars['String']>,
  uri?: Maybe<Scalars['String']>,
};

export enum ContentDisposition {
  Attachment = 'Attachment',
  Inline = 'Inline'
}

export type ContractorInvite = {
  companyName: Scalars['String'],
  email: Scalars['String'],
  familyName: Scalars['String'],
  givenName: Scalars['String'],
  phone: Scalars['String'],
};

export type ContractorProfile = {
  __typename?: 'ContractorProfile',
  acceptedLeads?: Maybe<Scalars['Int']>,
  budgetRangeSubscriptions?: Maybe<Array<ContractorProfileBudgetRangeSubscription>>,
  companyAddress?: Maybe<WeaverAddress>,
  companyNumber: Scalars['String'],
  companyRegisteredName: Scalars['String'],
  companyTradingAs?: Maybe<Scalars['String']>,
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  locationAreas?: Maybe<Array<Scalars['String']>>,
  onboardingPaymentEvent?: Maybe<Event>,
  rejectedLeads?: Maybe<Scalars['Int']>,
  updatedAt: Scalars['AWSDateTime'],
  workHistory?: Maybe<Array<ContractorProfileWorkHistory>>,
};

export type ContractorProfileBudgetRangeSubscription = {
  __typename?: 'ContractorProfileBudgetRangeSubscription',
  budgetRange: BudgetRange,
  id: Scalars['ID'],
};

export type ContractorProfileWorkHistory = {
  __typename?: 'ContractorProfileWorkHistory',
  constructionValue?: Maybe<Money>,
  id: Scalars['ID'],
  photoIds?: Maybe<Array<Scalars['String']>>,
  photos?: Maybe<Array<UploadedFile>>,
  projectAddress?: Maybe<WeaverAddress>,
  projectTypes?: Maybe<Array<ProjectType>>,
  references?: Maybe<Array<ContractorProfileWorkHistoryReference>>,
};

export type ContractorProfileWorkHistoryReference = {
  __typename?: 'ContractorProfileWorkHistoryReference',
  companyName?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  familyName: Scalars['String'],
  givenName: Scalars['String'],
  id: Scalars['ID'],
  phoneNumber: Scalars['String'],
  status: ProgressionStatus,
  teamType: TeamType,
};

export enum CorporateStructureType {
  LimitedCompany = 'LimitedCompany',
  Other = 'Other',
  Partnership = 'Partnership',
  SoleTrader = 'SoleTrader'
}

export type CreateArchitectProfile = {
  architectTeamId: Scalars['ID'],
  companyAddress?: InputMaybe<WeaverAddressInput>,
  companyNumber: Scalars['String'],
  companyRegisteredName: Scalars['String'],
  companyTradingAs?: InputMaybe<Scalars['String']>,
};

export type CreateChatRoom = {
  icon?: InputMaybe<Scalars['String']>,
  name: Scalars['String'],
  teamIds: Array<Scalars['ID']>,
};

export type CreateChatRoomMessageDocument = {
  chatRoomId: Scalars['ID'],
  chatRoomMessageIdFromClient: Scalars['ID'],
  fileContentType: Scalars['String'],
  fileName: Scalars['String'],
  fileSizeInBytes?: InputMaybe<Scalars['Int']>,
};

export type CreateContractorProfile = {
  companyAddress?: InputMaybe<WeaverAddressInput>,
  companyNumber: Scalars['String'],
  companyRegisteredName: Scalars['String'],
  companyTradingAs?: InputMaybe<Scalars['String']>,
  contractorTeamId: Scalars['ID'],
};

export type CreateContractorProfileWorkHistory = {
  constructionValue: MoneyInput,
  projectAddress?: InputMaybe<WeaverAddressInput>,
  projectTypes: Array<ProjectType>,
};

export type CreateContractorProfileWorkHistoryReference = {
  companyName?: InputMaybe<Scalars['String']>,
  email: Scalars['String'],
  familyName: Scalars['String'],
  givenName: Scalars['String'],
  phoneNumber: Scalars['String'],
  teamType: TeamType,
};

export type CreateContractorProfileWorkHistoryReferenceForWorkHistory = {
  id: Scalars['ID'],
  reference: CreateContractorProfileWorkHistoryReference,
};

export type CreateHomeownerProfile = {
  homeownerTeamId: Scalars['ID'],
  propertyAddress?: InputMaybe<WeaverAddressInput>,
  teamName?: InputMaybe<Scalars['String']>,
};

export type CreateProjectDocument = {
  fileContentType: Scalars['String'],
  fileName: Scalars['String'],
  fileSizeInBytes?: InputMaybe<Scalars['Int']>,
  projectId: Scalars['ID'],
  uploadedByTeamId: Scalars['String'],
};

export type CreateProjectInput = {
  address: WeaverAddressInput,
  budgetCategory: Scalars['String'],
  budgetValue: MoneyInput,
  contractorInvites: Array<ContractorInvite>,
  description: Scalars['String'],
  projectTypes: Array<ProjectType>,
  tenderReturnDate: Scalars['AWSDateTime'],
  totalContractors: Scalars['Int'],
  workStartEstimate: WorkStartEstimate,
};

export type CreateProjectTaskInput = {
  actionableType: Scalars['String'],
  assignedTeamId?: InputMaybe<Scalars['ID']>,
  dueAt?: InputMaybe<Scalars['AWSDateTime']>,
  icon?: InputMaybe<Scalars['String']>,
  order: Scalars['Int'],
  parentTaskId?: InputMaybe<Scalars['ID']>,
  projectId: Scalars['ID'],
  status: TaskStatus,
  title: Scalars['String'],
  url?: InputMaybe<Scalars['String']>,
};

export type CreateUploadedFileInput = {
  description?: InputMaybe<Scalars['String']>,
  fileContentType?: InputMaybe<Scalars['String']>,
  fileName?: InputMaybe<Scalars['String']>,
  fileSizeInBytes?: InputMaybe<Scalars['Int']>,
  tags?: InputMaybe<Array<CreateUploadedFileTagInput>>,
  title?: InputMaybe<Scalars['String']>,
};

export type CreateUploadedFileTagInput = {
  confidence?: InputMaybe<Scalars['Float']>,
  name: Scalars['String'],
  source: UploadedFileTagSource,
};

export enum Currency {
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD'
}

export type DownloadConfigInput = {
  disposition?: InputMaybe<ContentDisposition>,
  transformation?: InputMaybe<FileTransformationInput>,
};

export type Event = {
  __typename?: 'Event',
  actionedAt?: Maybe<Scalars['AWSDateTime']>,
  actionedByIndividualId?: Maybe<Scalars['ID']>,
};

export type EventInput = {
  actionedAt?: InputMaybe<Scalars['AWSDateTime']>,
  actionedByIndividualId?: InputMaybe<Scalars['ID']>,
};

export type FileTransformation = {
  __typename?: 'FileTransformation',
  height?: Maybe<Scalars['Int']>,
  ignoreAspectRatio?: Maybe<Scalars['Boolean']>,
  s3SubKey?: Maybe<Scalars['String']>,
  transformedContentType?: Maybe<Scalars['String']>,
  transformedExtension?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['Int']>,
};

export type FileTransformationInput = {
  height?: InputMaybe<Scalars['Int']>,
  ignoreAspectRatio?: InputMaybe<Scalars['Boolean']>,
  width?: InputMaybe<Scalars['Int']>,
};

export type HomeownerProfile = {
  __typename?: 'HomeownerProfile',
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  propertyAddress?: Maybe<WeaverAddress>,
  teamName?: Maybe<Scalars['String']>,
  updatedAt: Scalars['AWSDateTime'],
};

export type IncrementProjectDocumentCount = {
  documentCount: Scalars['Int'],
  projectId: Scalars['ID'],
};

export type Individual = {
  __typename?: 'Individual',
  chatRoomConnections: Array<IndividualChatRoomConnection>,
  createdAt: Scalars['AWSDateTime'],
  familyName: Scalars['String'],
  givenName: Scalars['String'],
  id: Scalars['ID'],
  lastSyncedAt: Scalars['AWSDateTime'],
  pictureURL: Scalars['String'],
  teamConnections: Array<IndividualTeamConnection>,
  updatedAt: Scalars['AWSDateTime'],
};

export type IndividualChatRoomConnection = {
  __typename?: 'IndividualChatRoomConnection',
  chatRoom: ChatRoom,
  chatRoomId: Scalars['ID'],
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  individual: Individual,
  individualId: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export type IndividualTeamConnection = {
  __typename?: 'IndividualTeamConnection',
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  individual: Individual,
  individualId: Scalars['ID'],
  team: Team,
  teamId: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export type InviteToMyProjectInput = {
  companyName?: InputMaybe<Scalars['String']>,
  email?: InputMaybe<Scalars['String']>,
  familyName?: InputMaybe<Scalars['String']>,
  givenName?: InputMaybe<Scalars['String']>,
  phone?: InputMaybe<Scalars['String']>,
  projectId: Scalars['ID'],
};

export enum LambdaResolver {
  Core = 'Core'
}

export type Lead = {
  __typename?: 'Lead',
  approximateLocation: Scalars['String'],
  budgetCategory: Scalars['String'],
  budgetValue: Money,
  description: Scalars['String'],
  id: Scalars['ID'],
  lastAccessed?: Maybe<Scalars['AWSDateTime']>,
  projectTypes: Array<ProjectType>,
  tenderReturnDate: Scalars['AWSDateTime'],
  title: Scalars['String'],
  workStartEstimate: WorkStartEstimate,
};

export type ListAllProjectsFilter = {
  assigneeTeamIdEquals?: InputMaybe<Scalars['ID']>,
  titleContains?: InputMaybe<Scalars['String']>,
  typeEquals?: InputMaybe<Scalars['String']>,
};

export enum LocationArea {
  LondonCentral = 'LondonCentral',
  LondonNorth = 'LondonNorth',
  LondonSouth = 'LondonSouth',
  LondonWest = 'LondonWest'
}

export type MessageSentInfo = {
  __typename?: 'MessageSentInfo',
  recipients?: Maybe<Array<Maybe<MessageSentInfoRecipient>>>,
};

export type MessageSentInfoRecipient = {
  __typename?: 'MessageSentInfoRecipient',
  familyName: Scalars['String'],
  givenName: Scalars['String'],
  individualId: Scalars['ID'],
  teamId: Scalars['ID'],
};

export type Money = {
  __typename?: 'Money',
  amountInPence: Scalars['Int'],
  currency: Currency,
};

export type MoneyInput = {
  amountInPence: Scalars['Int'],
  currency: Currency,
};

export type Mutation = {
  __typename?: 'Mutation',
  acceptLead: Scalars['Boolean'],
  addProjectInvite: Scalars['Boolean'],
  addProjectMember: Scalars['Boolean'],
  addProjectNote: Scalars['Boolean'],
  addProjectSlot: Scalars['Boolean'],
  archiveChatRoomMessageDocument: Scalars['Boolean'],
  archiveProjectDocument: Scalars['Boolean'],
  assignProjectToWeaverUser: Scalars['Boolean'],
  claimCompanyTeam: Team,
  claimInvite: Scalars['Boolean'],
  claimNonCompanyTeam: Team,
  createArchitectProfile: ArchitectProfile,
  createChatRoom: ChatRoom,
  createChatRoomMessageDocument?: Maybe<ChatRoomMessageDocument>,
  createContractorProfile: ContractorProfile,
  createContractorProfileWorkHistory: ContractorProfileWorkHistory,
  createContractorProfileWorkHistoryPhoto?: Maybe<UploadedFile>,
  createContractorProfileWorkHistoryReference: ContractorProfileWorkHistoryReference,
  createHomeownerProfile: HomeownerProfile,
  createProject: Project,
  createProjectDocument?: Maybe<ProjectDocument>,
  createProjectTask: Scalars['Boolean'],
  createStripeSessionForLeadPayment: StripeSession,
  createUploadedFile: UploadedFile,
  incrementProjectDocumentCount: Scalars['Boolean'],
  inviteToMyProject: ProjectMemberInvite,
  joinChatRoom?: Maybe<ChatRoom>,
  joinChatRoomForIndividual?: Maybe<ChatRoom>,
  joinWeaverTeam: Team,
  processProjectAction: Scalars['Boolean'],
  registerMyDeviceForPushNotifications: PushNotificationDevice,
  rejectLead: Scalars['Boolean'],
  removeIndividualFromTeam: Scalars['Boolean'],
  removeProjectInvite: Scalars['Boolean'],
  removeProjectMember: Scalars['Boolean'],
  removeProjectSlot: Scalars['Boolean'],
  removeUploadedFileTags: UploadedFile,
  resetContractorProfileWorkHistory: Scalars['Boolean'],
  sendChatRoomMessage?: Maybe<MessageSentInfo>,
  setIndividualPhoneNumber: Scalars['Boolean'],
  setOnboardingComplete: Scalars['Boolean'],
  triggerErrorForSentry: Scalars['Boolean'],
  updateArchitectProfile: ArchitectProfile,
  updateChatRoom?: Maybe<ChatRoom>,
  updateContractorProfile: ContractorProfile,
  updateContractorProfileWorkHistory: ContractorProfileWorkHistory,
  updateContractorProfileWorkHistoryReference: ContractorProfileWorkHistoryReference,
  updateHomeownerProfile: HomeownerProfile,
  updateProjectTaskStatus: Scalars['Boolean'],
  updateProjectWorkflow: Scalars['Boolean'],
  updateUploadedFile: UploadedFile,
};


export type MutationAcceptLeadArgs = {
  projectId: Scalars['ID'],
};


export type MutationAddProjectInviteArgs = {
  projectId: Scalars['ID'],
  projectMemberRole: ProjectMemberRole,
  teamId: Scalars['ID'],
};


export type MutationAddProjectMemberArgs = {
  projectId: Scalars['ID'],
  projectMemberRole: ProjectMemberRole,
  teamId: Scalars['ID'],
};


export type MutationAddProjectNoteArgs = {
  body: Scalars['String'],
  projectId: Scalars['ID'],
};


export type MutationAddProjectSlotArgs = {
  input: AddProjectSlotInput,
};


export type MutationArchiveChatRoomMessageDocumentArgs = {
  id: Scalars['ID'],
};


export type MutationArchiveProjectDocumentArgs = {
  id: Scalars['ID'],
};


export type MutationAssignProjectToWeaverUserArgs = {
  input?: InputMaybe<AssignProjectToWeaverUserInput>,
};


export type MutationClaimCompanyTeamArgs = {
  companyNumber: Scalars['String'],
  name: Scalars['String'],
  type: TeamType,
};


export type MutationClaimInviteArgs = {
  inviteId: Scalars['ID'],
};


export type MutationClaimNonCompanyTeamArgs = {
  name: Scalars['String'],
  type: TeamType,
};


export type MutationCreateArchitectProfileArgs = {
  input: CreateArchitectProfile,
};


export type MutationCreateChatRoomArgs = {
  input: CreateChatRoom,
};


export type MutationCreateChatRoomMessageDocumentArgs = {
  input: CreateChatRoomMessageDocument,
};


export type MutationCreateContractorProfileArgs = {
  input: CreateContractorProfile,
};


export type MutationCreateContractorProfileWorkHistoryArgs = {
  input: CreateContractorProfileWorkHistory,
};


export type MutationCreateContractorProfileWorkHistoryPhotoArgs = {
  uploadedFileId: Scalars['ID'],
  workHistoryId: Scalars['ID'],
};


export type MutationCreateContractorProfileWorkHistoryReferenceArgs = {
  input: CreateContractorProfileWorkHistoryReferenceForWorkHistory,
};


export type MutationCreateHomeownerProfileArgs = {
  input: CreateHomeownerProfile,
};


export type MutationCreateProjectArgs = {
  project: CreateProjectInput,
};


export type MutationCreateProjectDocumentArgs = {
  input: CreateProjectDocument,
};


export type MutationCreateProjectTaskArgs = {
  input: CreateProjectTaskInput,
};


export type MutationCreateStripeSessionForLeadPaymentArgs = {
  leadId: Scalars['ID'],
  product: StripeProduct,
  redirectBaseURL: Scalars['String'],
};


export type MutationCreateUploadedFileArgs = {
  input: CreateUploadedFileInput,
};


export type MutationIncrementProjectDocumentCountArgs = {
  input: IncrementProjectDocumentCount,
};


export type MutationInviteToMyProjectArgs = {
  input: InviteToMyProjectInput,
  requiredProjectRole: ProjectMemberRole,
  requiredTeamType: TeamType,
};


export type MutationJoinChatRoomArgs = {
  chatRoomId: Scalars['ID'],
};


export type MutationJoinChatRoomForIndividualArgs = {
  chatRoomId: Scalars['ID'],
  individualId: Scalars['ID'],
};


export type MutationProcessProjectActionArgs = {
  action: ProjectActionInput,
};


export type MutationRegisterMyDeviceForPushNotificationsArgs = {
  deviceToken: Scalars['String'],
};


export type MutationRejectLeadArgs = {
  projectId: Scalars['ID'],
};


export type MutationRemoveIndividualFromTeamArgs = {
  individualId: Scalars['String'],
  teamId: Scalars['String'],
};


export type MutationRemoveProjectInviteArgs = {
  id: Scalars['ID'],
};


export type MutationRemoveProjectMemberArgs = {
  id: Scalars['ID'],
};


export type MutationRemoveProjectSlotArgs = {
  id: Scalars['ID'],
};


export type MutationRemoveUploadedFileTagsArgs = {
  id: Scalars['ID'],
};


export type MutationSendChatRoomMessageArgs = {
  message: SendChatRoomMessage,
};


export type MutationSetIndividualPhoneNumberArgs = {
  individualId?: InputMaybe<Scalars['ID']>,
  phoneNumber: Scalars['String'],
};


export type MutationSetOnboardingCompleteArgs = {
  isOnboardingComplete: Scalars['Boolean'],
  teamId: Scalars['String'],
};


export type MutationUpdateArchitectProfileArgs = {
  input: UpdateArchitectProfile,
};


export type MutationUpdateChatRoomArgs = {
  input: UpdateChatRoom,
};


export type MutationUpdateContractorProfileArgs = {
  input: UpdateContractorProfile,
};


export type MutationUpdateContractorProfileWorkHistoryArgs = {
  input: UpdateContractorProfileWorkHistory,
};


export type MutationUpdateContractorProfileWorkHistoryReferenceArgs = {
  input: UpdateContractorProfileWorkHistoryReferenceForWorkHistory,
};


export type MutationUpdateHomeownerProfileArgs = {
  input: UpdateHomeownerProfile,
};


export type MutationUpdateProjectTaskStatusArgs = {
  input: UpdateProjectTaskStatus,
};


export type MutationUpdateProjectWorkflowArgs = {
  input: UpdateProjectWorkflowInput,
};


export type MutationUpdateUploadedFileArgs = {
  input: UpdateUploadedFileInput,
};

export enum ProgressionStatus {
  Completed = 'Completed',
  NotStarted = 'NotStarted',
  WaitingOnUser = 'WaitingOnUser',
  WaitingOnWeaver = 'WaitingOnWeaver'
}

export type Project = {
  __typename?: 'Project',
  acceptedQuoteId?: Maybe<Scalars['ID']>,
  address?: Maybe<WeaverAddress>,
  assigneeTeamId?: Maybe<Scalars['ID']>,
  budgetCategory: Scalars['String'],
  budgetValue: Money,
  createdAt: Scalars['AWSDateTime'],
  description: Scalars['String'],
  documentCount?: Maybe<Scalars['Int']>,
  documents?: Maybe<Array<ProjectDocument>>,
  id: Scalars['ID'],
  lastAccessed?: Maybe<Scalars['AWSDateTime']>,
  lastActivity?: Maybe<Scalars['AWSDateTime']>,
  memberInvites: Array<ProjectMemberInvite>,
  memberSlots: Array<ProjectMemberSlot>,
  members: Array<ProjectMember>,
  note?: Maybe<Scalars['String']>,
  projectTypes: Array<ProjectType>,
  quotes?: Maybe<Array<Quote>>,
  reference: Scalars['String'],
  siteVisits?: Maybe<Array<SiteVisit>>,
  status: ProjectStatus,
  tasks: Array<Task>,
  tenderReturnDate: Scalars['AWSDateTime'],
  title: Scalars['String'],
  updatedAt: Scalars['AWSDateTime'],
  workStartEstimate: WorkStartEstimate,
};


export type ProjectMemberInvitesArgs = {
  ignoreScope?: InputMaybe<Scalars['Boolean']>,
};


export type ProjectMembersArgs = {
  ignoreScope?: InputMaybe<Scalars['Boolean']>,
};


export type ProjectTasksArgs = {
  ignoreScope?: InputMaybe<Scalars['Boolean']>,
};

export type ProjectAction = {
  __typename?: 'ProjectAction',
  actionId: Scalars['String'],
  projectId: Scalars['ID'],
  teamId?: Maybe<Scalars['ID']>,
};

export type ProjectActionInput = {
  actionId: Scalars['String'],
  projectId: Scalars['ID'],
};

export type ProjectDocument = {
  __typename?: 'ProjectDocument',
  archived?: Maybe<Scalars['Boolean']>,
  createdAt: Scalars['AWSDateTime'],
  fileContentType: Scalars['String'],
  fileName: Scalars['String'],
  fileSizeInBytes?: Maybe<Scalars['Int']>,
  id: Scalars['ID'],
  project: Project,
  projectId: Scalars['ID'],
  s3Bucket: Scalars['String'],
  s3Key: Scalars['String'],
  signedUrlForDownload: Scalars['String'],
  signedUrlForUpload: Scalars['String'],
  updatedAt: Scalars['AWSDateTime'],
  uploadedByIndividual: Individual,
  uploadedByIndividualId: Scalars['String'],
  uploadedByTeam?: Maybe<Team>,
  uploadedByTeamId: Scalars['String'],
};


export type ProjectDocumentSignedUrlForDownloadArgs = {
  disposition?: InputMaybe<ContentDisposition>,
};

export type ProjectMember = {
  __typename?: 'ProjectMember',
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  projectId: Scalars['ID'],
  projectRole: ProjectMemberRole,
  team: Team,
  teamId: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export type ProjectMemberInvite = {
  __typename?: 'ProjectMemberInvite',
  companyName?: Maybe<Scalars['String']>,
  createdAt: Scalars['AWSDateTime'],
  email?: Maybe<Scalars['String']>,
  familyName?: Maybe<Scalars['String']>,
  givenName?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  phone?: Maybe<Scalars['String']>,
  projectId: Scalars['ID'],
  requiredProjectRole: ProjectMemberRole,
  requiredTeamType: TeamType,
  team?: Maybe<Team>,
  teamId?: Maybe<Scalars['ID']>,
  updatedAt: Scalars['AWSDateTime'],
};

export enum ProjectMemberRole {
  CandidateProfessional = 'CANDIDATE_PROFESSIONAL',
  Owner = 'OWNER',
  Professional = 'PROFESSIONAL'
}

export type ProjectMemberSlot = {
  __typename?: 'ProjectMemberSlot',
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  projectId: Scalars['ID'],
  requiredProjectRole: ProjectMemberRole,
  requiredTeamType: TeamType,
  updatedAt: Scalars['AWSDateTime'],
};

export type ProjectNote = {
  __typename?: 'ProjectNote',
  body: Scalars['String'],
  createdAt: Scalars['AWSDateTime'],
  createdByIndividual: Individual,
  createdByIndividualId: Scalars['ID'],
  id: Scalars['ID'],
  projectId: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export enum ProjectStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum ProjectType {
  BasementConversion = 'BasementConversion',
  ChangeOfUse = 'ChangeOfUse',
  Conservatory = 'Conservatory',
  FullRenovation = 'FullRenovation',
  LoftConversion = 'LoftConversion',
  NewBuild = 'NewBuild',
  RearExtension = 'RearExtension',
  Refurbishment = 'Refurbishment',
  SideExtension = 'SideExtension'
}

export type ProjectWorkflow = {
  __typename?: 'ProjectWorkflow',
  createdAt: Scalars['AWSDateTime'],
  projectId: Scalars['ID'],
  triggers: Array<WorkflowTrigger>,
  updatedAt: Scalars['AWSDateTime'],
};

export type PushNotificationDevice = {
  __typename?: 'PushNotificationDevice',
  createdAt: Scalars['AWSDateTime'],
  deviceToken: Scalars['String'],
  id: Scalars['ID'],
  individualId: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export enum PushNotificationType {
  NewChatRoomMessage = 'new_ChatRoomMessage'
}

export type Query = {
  __typename?: 'Query',
  getArchitectProfile?: Maybe<ArchitectProfile>,
  getChatRoom?: Maybe<ChatRoom>,
  getChatRoomMessageDocument: ChatRoomMessageDocument,
  getChatRoomMessages?: Maybe<Array<ChatRoomMessage>>,
  getContractorProfile?: Maybe<ContractorProfile>,
  getHomeownerProfile?: Maybe<HomeownerProfile>,
  getLead: Lead,
  getMyIndividual?: Maybe<Individual>,
  getPossibleCompanyNumbersForPartialCompanyName: CompaniesHouseResponse,
  getProject: Project,
  getProjectDocument: ProjectDocument,
  getUploadedFile: UploadedFile,
  hasCompanyNumberBeenClaimed: Scalars['Boolean'],
  listAllIndividuals: Array<Individual>,
  listAllProjects: Array<Project>,
  listAllTeams: Array<Team>,
  listAvailableChatRooms?: Maybe<Array<Maybe<ChatRoom>>>,
  listContractorSuggestionsForProject: Array<ContractorProfile>,
  listMyChatRooms?: Maybe<Array<Maybe<IndividualChatRoomConnection>>>,
  listMyLeads: Array<Lead>,
  listMyProjects: Array<Project>,
  listProjectNotes: Array<ProjectNote>,
  listProjectTasks: Array<Task>,
  listTeamChatRooms?: Maybe<Array<Maybe<ChatRoom>>>,
};


export type QueryGetArchitectProfileArgs = {
  architectTeamId: Scalars['ID'],
};


export type QueryGetChatRoomArgs = {
  id: Scalars['ID'],
};


export type QueryGetChatRoomMessageDocumentArgs = {
  chatRoomMessageDocumentId: Scalars['ID'],
};


export type QueryGetChatRoomMessagesArgs = {
  chatRoomId: Scalars['ID'],
};


export type QueryGetContractorProfileArgs = {
  contractorTeamId: Scalars['ID'],
};


export type QueryGetHomeownerProfileArgs = {
  homeownerTeamId: Scalars['ID'],
};


export type QueryGetLeadArgs = {
  id: Scalars['ID'],
};


export type QueryGetPossibleCompanyNumbersForPartialCompanyNameArgs = {
  partialCompanyName: Scalars['String'],
};


export type QueryGetProjectArgs = {
  id: Scalars['ID'],
  ignoreScope?: InputMaybe<Scalars['Boolean']>,
};


export type QueryGetProjectDocumentArgs = {
  projectDocumentId: Scalars['ID'],
};


export type QueryGetUploadedFileArgs = {
  id: Scalars['ID'],
};


export type QueryHasCompanyNumberBeenClaimedArgs = {
  companyNumber: Scalars['String'],
};


export type QueryListAllProjectsArgs = {
  filter?: InputMaybe<ListAllProjectsFilter>,
};


export type QueryListContractorSuggestionsForProjectArgs = {
  projectId: Scalars['ID'],
};


export type QueryListProjectNotesArgs = {
  projectId: Scalars['ID'],
};


export type QueryListProjectTasksArgs = {
  projectId: Scalars['ID'],
};


export type QueryListTeamChatRoomsArgs = {
  team1Id: Scalars['ID'],
  team2Id: Scalars['ID'],
};

export type Quote = {
  __typename?: 'Quote',
  createdByTeamId: Scalars['ID'],
  id: Scalars['ID'],
};

export enum ReferenceRoleType {
  Architect = 'Architect',
  Homeowner = 'Homeowner'
}

export type SendChatRoomMessage = {
  chatRoomId: Scalars['ID'],
  chatRoomMessageDocumentCount?: InputMaybe<Scalars['Int']>,
  content: Scalars['String'],
  idFromClient: Scalars['ID'],
  sentAt: Scalars['AWSDateTime'],
};

export type SignedUrl = {
  __typename?: 'SignedUrl',
  status: SignedUrlStatus,
  url?: Maybe<Scalars['String']>,
};

export enum SignedUrlStatus {
  ConfigError = 'ConfigError',
  FileNotFound = 'FileNotFound',
  Success = 'Success',
  TransformationMissing = 'TransformationMissing'
}

export type SiteVisit = {
  __typename?: 'SiteVisit',
  assignedTeamId: Scalars['ID'],
  dueDate: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  status: SiteVisitStatus,
};

export enum SiteVisitStatus {
  Completed = 'COMPLETED',
  Scheduled = 'SCHEDULED'
}

export enum StripeCallback {
  Default = 'Default',
  LeadPayment = 'LeadPayment'
}

export enum StripeProduct {
  SingleLeadPaymentF30T100 = 'SingleLeadPaymentF30T100',
  SingleLeadPaymentF100T250 = 'SingleLeadPaymentF100T250',
  SingleLeadPaymentF250T500 = 'SingleLeadPaymentF250T500',
  SingleLeadPaymentF500T1000 = 'SingleLeadPaymentF500T1000',
  SingleLeadPaymentF1000T3000 = 'SingleLeadPaymentF1000T3000',
  UnlimitedLeadSubscriptionF30T100 = 'UnlimitedLeadSubscriptionF30T100',
  UnlimitedLeadSubscriptionF100T250 = 'UnlimitedLeadSubscriptionF100T250',
  UnlimitedLeadSubscriptionF250T500 = 'UnlimitedLeadSubscriptionF250T500',
  UnlimitedLeadSubscriptionF500T1000 = 'UnlimitedLeadSubscriptionF500T1000',
  UnlimitedLeadSubscriptionF1000T3000 = 'UnlimitedLeadSubscriptionF1000T3000'
}

export type StripeSession = {
  __typename?: 'StripeSession',
  id: Scalars['String'],
  url?: Maybe<Scalars['String']>,
};

export type Task = {
  __typename?: 'Task',
  actionableType: Scalars['String'],
  activeStatus: TaskStatus,
  assignedTeam?: Maybe<Team>,
  assignedTeamId?: Maybe<Scalars['ID']>,
  childTasks: Array<Task>,
  createdAt: Scalars['AWSDateTime'],
  dueAt?: Maybe<Scalars['AWSDateTime']>,
  icon?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  isConfidential?: Maybe<Scalars['Boolean']>,
  order: Scalars['Int'],
  parentTask?: Maybe<Task>,
  parentTaskId?: Maybe<Scalars['ID']>,
  project: Project,
  projectId: Scalars['ID'],
  status: TaskStatus,
  title: Scalars['String'],
  updatedAt: Scalars['AWSDateTime'],
  url?: Maybe<Scalars['String']>,
};

export enum TaskStatus {
  Completed = 'COMPLETED',
  Disabled = 'DISABLED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED',
  Overdue = 'OVERDUE'
}

export type Team = {
  __typename?: 'Team',
  companyNumber?: Maybe<Scalars['String']>,
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  individualConnections: Array<IndividualTeamConnection>,
  isOnboardingComplete?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  type: TeamType,
  updatedAt: Scalars['AWSDateTime'],
};

export type TeamChatRoomConnection = {
  __typename?: 'TeamChatRoomConnection',
  chatRoom: ChatRoom,
  chatRoomId: Scalars['ID'],
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  team: Team,
  teamId: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export enum TeamKind {
  Company = 'COMPANY',
  NonCompany = 'NON_COMPANY'
}

export enum TeamType {
  Architect = 'ARCHITECT',
  Contractor = 'CONTRACTOR',
  Homeowner = 'HOMEOWNER',
  Weaver = 'WEAVER'
}

export type UpdateArchitectProfile = {
  companyAddress?: InputMaybe<WeaverAddressInput>,
  companyTradingAs?: InputMaybe<Scalars['String']>,
  id: Scalars['ID'],
};

export type UpdateChatRoom = {
  icon?: InputMaybe<Scalars['String']>,
  id: Scalars['ID'],
  name?: InputMaybe<Scalars['String']>,
};

export type UpdateContractorProfile = {
  companyAddress?: InputMaybe<WeaverAddressInput>,
  companyTradingAs?: InputMaybe<Scalars['String']>,
  id: Scalars['ID'],
  locationAreas?: InputMaybe<Array<Scalars['String']>>,
  onboardingPaymentEvent?: InputMaybe<EventInput>,
};

export type UpdateContractorProfileWorkHistory = {
  constructionValue?: InputMaybe<MoneyInput>,
  id: Scalars['ID'],
  projectAddress?: InputMaybe<WeaverAddressInput>,
  projectTypes?: InputMaybe<Array<ProjectType>>,
};

export type UpdateContractorProfileWorkHistoryReference = {
  companyName?: InputMaybe<Scalars['String']>,
  email?: InputMaybe<Scalars['String']>,
  familyName?: InputMaybe<Scalars['String']>,
  givenName?: InputMaybe<Scalars['String']>,
  id: Scalars['ID'],
  phoneNumber?: InputMaybe<Scalars['String']>,
  status?: InputMaybe<ProgressionStatus>,
  teamType?: InputMaybe<TeamType>,
};

export type UpdateContractorProfileWorkHistoryReferenceForWorkHistory = {
  id: Scalars['ID'],
  reference: UpdateContractorProfileWorkHistoryReference,
};

export type UpdateHomeownerProfile = {
  id: Scalars['ID'],
  propertyAddress?: InputMaybe<WeaverAddressInput>,
  teamName?: InputMaybe<Scalars['String']>,
};

export type UpdateProjectTaskStatus = {
  projectId: Scalars['ID'],
  status: TaskStatus,
  taskId?: InputMaybe<Scalars['ID']>,
};

export type UpdateProjectWorkflowInput = {
  projectId: Scalars['ID'],
  triggers: Array<WorkflowTriggerInput>,
};

export type UpdateUploadedFileInput = {
  description?: InputMaybe<Scalars['String']>,
  id: Scalars['ID'],
  status?: InputMaybe<UploadedFileStatus>,
  tags?: InputMaybe<Array<UpdateUploadedFileTagInput>>,
  title?: InputMaybe<Scalars['String']>,
};

export type UpdateUploadedFileTagInput = {
  confidence?: InputMaybe<Scalars['Float']>,
  name: Scalars['String'],
  source: UploadedFileTagSource,
};

export type UploadedFile = {
  __typename?: 'UploadedFile',
  createdAt: Scalars['AWSDateTime'],
  description?: Maybe<Scalars['String']>,
  fileContentType?: Maybe<Scalars['String']>,
  fileName?: Maybe<Scalars['String']>,
  fileSizeInBytes?: Maybe<Scalars['Int']>,
  id: Scalars['ID'],
  s3Bucket: Scalars['String'],
  s3Key: Scalars['String'],
  signedUrlForDownload: SignedUrl,
  signedUrlForUpload: SignedUrl,
  status?: Maybe<UploadedFileStatus>,
  tags?: Maybe<Array<UploadedFileTag>>,
  title?: Maybe<Scalars['String']>,
  transformations?: Maybe<Array<FileTransformation>>,
  updatedAt: Scalars['AWSDateTime'],
  uploadedByIndividual?: Maybe<Individual>,
  uploadedByIndividualId: Scalars['ID'],
  uploadedByTeam?: Maybe<Team>,
  uploadedByTeamId: Scalars['ID'],
};


export type UploadedFileSignedUrlForDownloadArgs = {
  config?: InputMaybe<DownloadConfigInput>,
};

export enum UploadedFileStatus {
  Archived = 'Archived',
  Completed = 'Completed',
  Failed = 'Failed',
  NotStarted = 'NotStarted',
  Uploading = 'Uploading'
}

export type UploadedFileTag = {
  __typename?: 'UploadedFileTag',
  confidence?: Maybe<Scalars['Float']>,
  name: Scalars['String'],
  source: UploadedFileTagSource,
};

export enum UploadedFileTagSource {
  MachineLearning = 'MachineLearning',
  User = 'User'
}

export type WeaverAddress = {
  __typename?: 'WeaverAddress',
  addressLine1?: Maybe<Scalars['String']>,
  addressLine2?: Maybe<Scalars['String']>,
  careOf?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  county?: Maybe<Scalars['String']>,
  poBox?: Maybe<Scalars['String']>,
  postCode: Scalars['String'],
  postTown?: Maybe<Scalars['String']>,
};

export type WeaverAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']>,
  addressLine2?: InputMaybe<Scalars['String']>,
  careOf?: InputMaybe<Scalars['String']>,
  country?: InputMaybe<Scalars['String']>,
  county?: InputMaybe<Scalars['String']>,
  poBox?: InputMaybe<Scalars['String']>,
  postCode: Scalars['String'],
  postTown?: InputMaybe<Scalars['String']>,
};

export enum WorkStartEstimate {
  LessThanMonth = 'LESS_THAN_MONTH',
  OneThreeMonths = 'ONE_THREE_MONTHS',
  OverSixMonths = 'OVER_SIX_MONTHS',
  ThreeSixMonths = 'THREE_SIX_MONTHS',
  Unknown = 'UNKNOWN'
}

export type WorkflowTrigger = {
  __typename?: 'WorkflowTrigger',
  actionId: Scalars['String'],
  actionableType: Scalars['String'],
  taskStatus: TaskStatus,
};

export type WorkflowTriggerInput = {
  actionId: Scalars['String'],
  actionableType: Scalars['String'],
  taskStatus: TaskStatus,
};

export type GetMyIndividualQueryVariables = Exact<{ [key: string]: never }>;


export type GetMyIndividualQuery = { __typename?: 'Query', getMyIndividual?: { __typename?: 'Individual', id: string, createdAt: string, updatedAt: string, givenName: string, familyName: string, pictureURL: string, lastSyncedAt: string, teamConnections: Array<{ __typename?: 'IndividualTeamConnection', id: string, team: { __typename?: 'Team', id: string, name?: string | null, type: TeamType, companyNumber?: string | null } }> } | null };

export type DashboardProjectFieldsFragment = { __typename?: 'Project', assigneeTeamId?: string | null, id: string, lastActivity?: string | null, note?: string | null, projectTypes: Array<ProjectType>, documentCount?: number | null, title: string, memberSlots: Array<{ __typename?: 'ProjectMemberSlot', id: string, requiredProjectRole: ProjectMemberRole, requiredTeamType: TeamType, createdAt: string, updatedAt: string }>, memberInvites: Array<{ __typename?: 'ProjectMemberInvite', id: string, companyName?: string | null, givenName?: string | null, familyName?: string | null, email?: string | null, phone?: string | null, createdAt: string, updatedAt: string, team?: { __typename?: 'Team', id: string, name?: string | null, type: TeamType } | null }>, members: Array<{ __typename?: 'ProjectMember', id: string, projectRole: ProjectMemberRole, createdAt: string, updatedAt: string, team: { __typename?: 'Team', id: string, name?: string | null, type: TeamType } }>, quotes?: Array<{ __typename?: 'Quote', id: string }> | null, siteVisits?: Array<{ __typename?: 'SiteVisit', id: string }> | null, tasks: Array<{ __typename?: 'Task', id: string, status: TaskStatus, actionableType: string, title: string, order: number, activeStatus: TaskStatus }> };

export type ListAllProjectsQueryVariables = Exact<{
  filter?: InputMaybe<ListAllProjectsFilter>,
}>;


export type ListAllProjectsQuery = { __typename?: 'Query', listAllProjects: Array<{ __typename?: 'Project', assigneeTeamId?: string | null, id: string, lastActivity?: string | null, note?: string | null, projectTypes: Array<ProjectType>, documentCount?: number | null, title: string, memberSlots: Array<{ __typename?: 'ProjectMemberSlot', id: string, requiredProjectRole: ProjectMemberRole, requiredTeamType: TeamType, createdAt: string, updatedAt: string }>, memberInvites: Array<{ __typename?: 'ProjectMemberInvite', id: string, companyName?: string | null, givenName?: string | null, familyName?: string | null, email?: string | null, phone?: string | null, createdAt: string, updatedAt: string, team?: { __typename?: 'Team', id: string, name?: string | null, type: TeamType } | null }>, members: Array<{ __typename?: 'ProjectMember', id: string, projectRole: ProjectMemberRole, createdAt: string, updatedAt: string, team: { __typename?: 'Team', id: string, name?: string | null, type: TeamType } }>, quotes?: Array<{ __typename?: 'Quote', id: string }> | null, siteVisits?: Array<{ __typename?: 'SiteVisit', id: string }> | null, tasks: Array<{ __typename?: 'Task', id: string, status: TaskStatus, actionableType: string, title: string, order: number, activeStatus: TaskStatus }> }> };

export type RefreshSingleProjectQueryVariables = Exact<{
  projectId: Scalars['ID'],
}>;


export type RefreshSingleProjectQuery = { __typename?: 'Query', getProject: { __typename?: 'Project', assigneeTeamId?: string | null, id: string, lastActivity?: string | null, note?: string | null, projectTypes: Array<ProjectType>, documentCount?: number | null, title: string, memberSlots: Array<{ __typename?: 'ProjectMemberSlot', id: string, requiredProjectRole: ProjectMemberRole, requiredTeamType: TeamType, createdAt: string, updatedAt: string }>, memberInvites: Array<{ __typename?: 'ProjectMemberInvite', id: string, companyName?: string | null, givenName?: string | null, familyName?: string | null, email?: string | null, phone?: string | null, createdAt: string, updatedAt: string, team?: { __typename?: 'Team', id: string, name?: string | null, type: TeamType } | null }>, members: Array<{ __typename?: 'ProjectMember', id: string, projectRole: ProjectMemberRole, createdAt: string, updatedAt: string, team: { __typename?: 'Team', id: string, name?: string | null, type: TeamType } }>, quotes?: Array<{ __typename?: 'Quote', id: string }> | null, siteVisits?: Array<{ __typename?: 'SiteVisit', id: string }> | null, tasks: Array<{ __typename?: 'Task', id: string, status: TaskStatus, actionableType: string, title: string, order: number, activeStatus: TaskStatus }> } };

export type ListContractorSuggestionsForProjectQueryVariables = Exact<{
  projectId: Scalars['ID'],
}>;


export type ListContractorSuggestionsForProjectQuery = { __typename?: 'Query', listContractorSuggestionsForProject: Array<{ __typename?: 'ContractorProfile', id: string, companyRegisteredName: string, companyTradingAs?: string | null, acceptedLeads?: number | null, rejectedLeads?: number | null, companyNumber: string }> };

export type AddProjectSlotMutationVariables = Exact<{
  input: AddProjectSlotInput,
}>;


export type AddProjectSlotMutation = { __typename?: 'Mutation', addProjectSlot: boolean };

export type AddProjectInviteMutationVariables = Exact<{
  projectId: Scalars['ID'],
  teamId: Scalars['ID'],
  projectMemberRole: ProjectMemberRole,
}>;


export type AddProjectInviteMutation = { __typename?: 'Mutation', addProjectInvite: boolean };

export type RemoveProjectInviteMutationVariables = Exact<{
  id: Scalars['ID'],
}>;


export type RemoveProjectInviteMutation = { __typename?: 'Mutation', removeProjectInvite: boolean };

export type RemoveProjectMemberMutationVariables = Exact<{
  id: Scalars['ID'],
}>;


export type RemoveProjectMemberMutation = { __typename?: 'Mutation', removeProjectMember: boolean };

export type RemoveProjectSlotMutationVariables = Exact<{
  id: Scalars['ID'],
}>;


export type RemoveProjectSlotMutation = { __typename?: 'Mutation', removeProjectSlot: boolean };

export type ListProjectNotesQueryVariables = Exact<{
  id: Scalars['ID'],
}>;


export type ListProjectNotesQuery = { __typename?: 'Query', listProjectNotes: Array<{ __typename?: 'ProjectNote', id: string, body: string, createdAt: string, updatedAt: string, createdByIndividual: { __typename?: 'Individual', id: string, pictureURL: string, givenName: string, familyName: string } }> };

export type AddProjectNoteMutationVariables = Exact<{
  parentId: Scalars['ID'],
  body: Scalars['String'],
}>;


export type AddProjectNoteMutation = { __typename?: 'Mutation', addProjectNote: boolean };

export const DashboardProjectFieldsFragmentDoc = `
    fragment DashboardProjectFields on Project {
  assigneeTeamId
  id
  lastActivity
  memberSlots {
    id
    requiredProjectRole
    requiredTeamType
    createdAt
    updatedAt
  }
  memberInvites(ignoreScope: true) {
    id
    team {
      id
      name
      type
    }
    companyName
    givenName
    familyName
    email
    phone
    createdAt
    updatedAt
  }
  members(ignoreScope: true) {
    id
    team {
      id
      name
      type
    }
    projectRole
    createdAt
    updatedAt
  }
  note
  projectTypes
  quotes {
    id
  }
  siteVisits {
    id
  }
  tasks(ignoreScope: true) {
    id
    status
    actionableType
    title
    order
    activeStatus
  }
  documentCount
  title
}
    `
export const GetMyIndividualDocument = `
    query getMyIndividual {
  getMyIndividual {
    id
    createdAt
    updatedAt
    givenName
    familyName
    pictureURL
    lastSyncedAt
    teamConnections {
      id
      team {
        id
        name
        type
        companyNumber
      }
    }
  }
}
    `
export const useGetMyIndividualQuery = <
  TData = GetMyIndividualQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: GetMyIndividualQueryVariables,
  options?: UseQueryOptions<GetMyIndividualQuery, TError, TData>,
) =>
  useQuery<GetMyIndividualQuery, TError, TData>(
    variables === undefined ? [ 'getMyIndividual' ] : [ 'getMyIndividual', variables ],
    fetcher<GetMyIndividualQuery, GetMyIndividualQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetMyIndividualDocument, variables),
    options,
  )
useGetMyIndividualQuery.document = GetMyIndividualDocument


useGetMyIndividualQuery.getKey = (variables?: GetMyIndividualQueryVariables) => variables === undefined ? [ 'getMyIndividual' ] : [ 'getMyIndividual', variables ]


useGetMyIndividualQuery.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables?: GetMyIndividualQueryVariables) => fetcher<GetMyIndividualQuery, GetMyIndividualQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetMyIndividualDocument, variables)
export const ListAllProjectsDocument = `
    query listAllProjects($filter: ListAllProjectsFilter) {
  listAllProjects(filter: $filter) {
    ...DashboardProjectFields
  }
}
    ${DashboardProjectFieldsFragmentDoc}`
export const useListAllProjectsQuery = <
  TData = ListAllProjectsQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: ListAllProjectsQueryVariables,
  options?: UseQueryOptions<ListAllProjectsQuery, TError, TData>,
) =>
  useQuery<ListAllProjectsQuery, TError, TData>(
    variables === undefined ? [ 'listAllProjects' ] : [ 'listAllProjects', variables ],
    fetcher<ListAllProjectsQuery, ListAllProjectsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListAllProjectsDocument, variables),
    options,
  )
useListAllProjectsQuery.document = ListAllProjectsDocument


useListAllProjectsQuery.getKey = (variables?: ListAllProjectsQueryVariables) => variables === undefined ? [ 'listAllProjects' ] : [ 'listAllProjects', variables ]


useListAllProjectsQuery.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables?: ListAllProjectsQueryVariables) => fetcher<ListAllProjectsQuery, ListAllProjectsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListAllProjectsDocument, variables)
export const RefreshSingleProjectDocument = `
    query refreshSingleProject($projectId: ID!) {
  getProject(id: $projectId, ignoreScope: true) {
    ...DashboardProjectFields
  }
}
    ${DashboardProjectFieldsFragmentDoc}`
export const useRefreshSingleProjectQuery = <
  TData = RefreshSingleProjectQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: RefreshSingleProjectQueryVariables,
  options?: UseQueryOptions<RefreshSingleProjectQuery, TError, TData>,
) =>
  useQuery<RefreshSingleProjectQuery, TError, TData>(
    [ 'refreshSingleProject', variables ],
    fetcher<RefreshSingleProjectQuery, RefreshSingleProjectQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, RefreshSingleProjectDocument, variables),
    options,
  )
useRefreshSingleProjectQuery.document = RefreshSingleProjectDocument


useRefreshSingleProjectQuery.getKey = (variables: RefreshSingleProjectQueryVariables) => [ 'refreshSingleProject', variables ]


useRefreshSingleProjectQuery.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: RefreshSingleProjectQueryVariables) => fetcher<RefreshSingleProjectQuery, RefreshSingleProjectQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, RefreshSingleProjectDocument, variables)
export const ListContractorSuggestionsForProjectDocument = `
    query listContractorSuggestionsForProject($projectId: ID!) {
  listContractorSuggestionsForProject(projectId: $projectId) {
    id
    companyRegisteredName
    companyTradingAs
    acceptedLeads
    rejectedLeads
    companyTradingAs
    companyNumber
  }
}
    `
export const useListContractorSuggestionsForProjectQuery = <
  TData = ListContractorSuggestionsForProjectQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: ListContractorSuggestionsForProjectQueryVariables,
  options?: UseQueryOptions<ListContractorSuggestionsForProjectQuery, TError, TData>,
) =>
  useQuery<ListContractorSuggestionsForProjectQuery, TError, TData>(
    [ 'listContractorSuggestionsForProject', variables ],
    fetcher<ListContractorSuggestionsForProjectQuery, ListContractorSuggestionsForProjectQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListContractorSuggestionsForProjectDocument, variables),
    options,
  )
useListContractorSuggestionsForProjectQuery.document = ListContractorSuggestionsForProjectDocument


useListContractorSuggestionsForProjectQuery.getKey = (variables: ListContractorSuggestionsForProjectQueryVariables) => [ 'listContractorSuggestionsForProject', variables ]


useListContractorSuggestionsForProjectQuery.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: ListContractorSuggestionsForProjectQueryVariables) => fetcher<ListContractorSuggestionsForProjectQuery, ListContractorSuggestionsForProjectQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListContractorSuggestionsForProjectDocument, variables)
export const AddProjectSlotDocument = `
    mutation addProjectSlot($input: AddProjectSlotInput!) {
  addProjectSlot(input: $input)
}
    `
export const useAddProjectSlotMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<AddProjectSlotMutation, TError, AddProjectSlotMutationVariables, TContext>,
) =>
  useMutation<AddProjectSlotMutation, TError, AddProjectSlotMutationVariables, TContext>(
    [ 'addProjectSlot' ],
    (variables?: AddProjectSlotMutationVariables) => fetcher<AddProjectSlotMutation, AddProjectSlotMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AddProjectSlotDocument, variables)(),
    options,
  )
useAddProjectSlotMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: AddProjectSlotMutationVariables) => fetcher<AddProjectSlotMutation, AddProjectSlotMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AddProjectSlotDocument, variables)
export const AddProjectInviteDocument = `
    mutation addProjectInvite($projectId: ID!, $teamId: ID!, $projectMemberRole: ProjectMemberRole!) {
  addProjectInvite(
    projectId: $projectId
    teamId: $teamId
    projectMemberRole: $projectMemberRole
  )
}
    `
export const useAddProjectInviteMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<AddProjectInviteMutation, TError, AddProjectInviteMutationVariables, TContext>,
) =>
  useMutation<AddProjectInviteMutation, TError, AddProjectInviteMutationVariables, TContext>(
    [ 'addProjectInvite' ],
    (variables?: AddProjectInviteMutationVariables) => fetcher<AddProjectInviteMutation, AddProjectInviteMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AddProjectInviteDocument, variables)(),
    options,
  )
useAddProjectInviteMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: AddProjectInviteMutationVariables) => fetcher<AddProjectInviteMutation, AddProjectInviteMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AddProjectInviteDocument, variables)
export const RemoveProjectInviteDocument = `
    mutation removeProjectInvite($id: ID!) {
  removeProjectInvite(id: $id)
}
    `
export const useRemoveProjectInviteMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<RemoveProjectInviteMutation, TError, RemoveProjectInviteMutationVariables, TContext>,
) =>
  useMutation<RemoveProjectInviteMutation, TError, RemoveProjectInviteMutationVariables, TContext>(
    [ 'removeProjectInvite' ],
    (variables?: RemoveProjectInviteMutationVariables) => fetcher<RemoveProjectInviteMutation, RemoveProjectInviteMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, RemoveProjectInviteDocument, variables)(),
    options,
  )
useRemoveProjectInviteMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: RemoveProjectInviteMutationVariables) => fetcher<RemoveProjectInviteMutation, RemoveProjectInviteMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, RemoveProjectInviteDocument, variables)
export const RemoveProjectMemberDocument = `
    mutation removeProjectMember($id: ID!) {
  removeProjectMember(id: $id)
}
    `
export const useRemoveProjectMemberMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<RemoveProjectMemberMutation, TError, RemoveProjectMemberMutationVariables, TContext>,
) =>
  useMutation<RemoveProjectMemberMutation, TError, RemoveProjectMemberMutationVariables, TContext>(
    [ 'removeProjectMember' ],
    (variables?: RemoveProjectMemberMutationVariables) => fetcher<RemoveProjectMemberMutation, RemoveProjectMemberMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, RemoveProjectMemberDocument, variables)(),
    options,
  )
useRemoveProjectMemberMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: RemoveProjectMemberMutationVariables) => fetcher<RemoveProjectMemberMutation, RemoveProjectMemberMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, RemoveProjectMemberDocument, variables)
export const RemoveProjectSlotDocument = `
    mutation removeProjectSlot($id: ID!) {
  removeProjectSlot(id: $id)
}
    `
export const useRemoveProjectSlotMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<RemoveProjectSlotMutation, TError, RemoveProjectSlotMutationVariables, TContext>,
) =>
  useMutation<RemoveProjectSlotMutation, TError, RemoveProjectSlotMutationVariables, TContext>(
    [ 'removeProjectSlot' ],
    (variables?: RemoveProjectSlotMutationVariables) => fetcher<RemoveProjectSlotMutation, RemoveProjectSlotMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, RemoveProjectSlotDocument, variables)(),
    options,
  )
useRemoveProjectSlotMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: RemoveProjectSlotMutationVariables) => fetcher<RemoveProjectSlotMutation, RemoveProjectSlotMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, RemoveProjectSlotDocument, variables)
export const ListProjectNotesDocument = `
    query listProjectNotes($id: ID!) {
  listProjectNotes(projectId: $id) {
    id
    body
    createdAt
    updatedAt
    createdByIndividual {
      id
      pictureURL
      givenName
      familyName
    }
  }
}
    `
export const useListProjectNotesQuery = <
  TData = ListProjectNotesQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: ListProjectNotesQueryVariables,
  options?: UseQueryOptions<ListProjectNotesQuery, TError, TData>,
) =>
  useQuery<ListProjectNotesQuery, TError, TData>(
    [ 'listProjectNotes', variables ],
    fetcher<ListProjectNotesQuery, ListProjectNotesQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListProjectNotesDocument, variables),
    options,
  )
useListProjectNotesQuery.document = ListProjectNotesDocument


useListProjectNotesQuery.getKey = (variables: ListProjectNotesQueryVariables) => [ 'listProjectNotes', variables ]


useListProjectNotesQuery.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: ListProjectNotesQueryVariables) => fetcher<ListProjectNotesQuery, ListProjectNotesQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListProjectNotesDocument, variables)
export const AddProjectNoteDocument = `
    mutation addProjectNote($parentId: ID!, $body: String!) {
  addProjectNote(projectId: $parentId, body: $body)
}
    `
export const useAddProjectNoteMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<AddProjectNoteMutation, TError, AddProjectNoteMutationVariables, TContext>,
) =>
  useMutation<AddProjectNoteMutation, TError, AddProjectNoteMutationVariables, TContext>(
    [ 'addProjectNote' ],
    (variables?: AddProjectNoteMutationVariables) => fetcher<AddProjectNoteMutation, AddProjectNoteMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AddProjectNoteDocument, variables)(),
    options,
  )
useAddProjectNoteMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: AddProjectNoteMutationVariables) => fetcher<AddProjectNoteMutation, AddProjectNoteMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AddProjectNoteDocument, variables)